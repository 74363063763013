<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>
            Fuel - Delivery History
            <router-link to="/admin/fuel/deliveries">
              <v-btn small class="float-right">Add delivery</v-btn>
            </router-link>
          </h1>
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col class="col-12">
          <v-simple-table
            dense
            fixed-header
            height="450px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Date</th>
                  <th class="text-left">Quantity (litres)</th>
                  <th class="text-left">Price per litre</th>
                  <th class="text-left">Supplier</th>
                  <th class="text-left">Reference</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(delivery, index) in fuelDeliveries"
                  :key="index"
                >
                  <td>{{ delivery.delivery_date | tinyDate }}</td>
                  <td>{{ delivery.quantity_litres | numeral('0,0') }}</td>
                  <td>&pound;{{ (delivery.price_per_litre / 100000) }}</td>
                  <td>{{ delivery.supplier }}</td>
                  <td>{{ delivery.reference }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
          ></v-pagination>
          </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from '../../../axios';
  
  export default {
    name: 'AdminFuelIndex',
    computed: {
      token() {
        return this.$store.state.token;
      },
    },
    data() {
      return {
        fuelDeliveries: [],
        page: 1,
        length: 0,
        totalVisible: 9,
      };
    },
    watch: {
      page() {
        this.getBoqs();
      },
    },
    methods: {
      getFuelDeliveries() {
        axios.get(`/fuelDeliveries/getPaginated/${this.page}.json?token=${this.token}&term=${this.searchTerm}&userid=${this.userid}`)
          .then((response) => {
            this.fuelDeliveries = response.data.fuelDeliveries;
            this.length = response.data.length;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getFuelDeliveries();
    },
  };
  </script>
  